.logo {
  a {
    background-image: url('../images/logo.svg');
    width: 255px;
    height: 50px;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    @media(max-width: 1600px) {
      width: 195px;
      height: 40px; } } }
