.convenient-topics {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: center;
  .item {

    a,
    .inner {

      box-shadow: 0 3px 15px rgba(90, 81, 212, 0.15);
      border-radius: 15px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 25px 35px;
      margin: 0 15px;
      margin-top: 40px;

      @include below(desktop) {
        margin-top: 15px;
        padding: 15px 10px; } }

    p {
      color: #002a54;
      font-size: 25px;
      font-weight: 500;
      margin-right: 20px;

      @include below(desktop) {
        font-size: 18px; } }

    img {
      width: 46px;
      height: 46px; } } }


.content-employer {
  .card-employer--content {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 60px;
    display: grid;
    margin-top: 100px;

    @include above(tablet) {
      grid-template-columns: repeat(3, 3fr);
      grid-gap: 60px;
      display: grid; }

    .card-news-inner {
      color: $blue;

      .card-text-content {
        box-shadow: 0 5px 25px rgba(179, 185, 207, 0.3);
        border-radius: 20px 0 0 20px;
        background-color: #ffffff;
        margin-top: -25px;
        z-index: 0;
        position: relative;
        padding: 50px 30px 40px 30px;
        display: inline-block; }

      .card-title {
        color: #002a54;
        font-size: 34px;
        font-weight: 600;
        line-height: 45px; }

      .card-text {
        margin-top: 25px; } }


    .card-image {
      width: 90%;
      height: 247px;
      border-radius: 20px 0 0 20px;
      background-size: cover;
      background-position: center center;
      position: relative;
      float: right; } } }


.content-benefits {
  margin-bottom: 200px;
  @include below(desktop) {
    margin-bottom: 80px; }
  .benefits-list {

    color: $blue;

    @include above(tablet) {
      display: grid;
      grid-template-columns: repeat(2, 2fr);
      grid-gap: 110px; }

    .item {
      margin-top: 90px;

      @include below(desktop) {
        margin-top: 50px; }

      span.circle {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        position: relative;

        &::before {
          content: '';
          width: 33px;
          height: 32px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center; } }

      .title {
        font-size: 35px;
        font-weight: 600;
        margin-bottom: 10px; }
      .topics {
        color: #002a54;
        font-size: 24px;
        font-weight: 400;
        line-height: 42px; }

      .button-primary {
        margin-top: 90px;

        @include below(desktop) {
          margin-top: 0;
          width: 100%; }
        a {
          width: 370px;
          height: 110px;
          font-size: 24px;
          text-align: center;

          @include below(desktop) {
            width: 100%;
            font-size: 20px; } } }

      &:nth-child(1) {
        span.circle {
          background-color: #ffb56b;

          &::before {
            background-image: url('../images/Icon_map-health.svg');
            display: inline-block; } } }

      &:nth-child(2) {
        span.circle {
          background-color: #8ad4d4;

          &::before {
            background-image: url('../images/Icon_material-local-pharmacy.png');
            display: inline-block; } } }

      &:nth-child(3) {
        span.circle {
          background-color: #c9a7ef;
          &::before {
            background-image: url('../images/Icon_awesome-laptop-medical.svg');
            display: inline-block; } } }

      &:nth-child(4) {
        span.circle {
          background-color: #1185ff;

          &::before {
            background-image: url('../images/Icon_map-doctor.svg');
            display: inline-block; } } }

      &:nth-child(5) {
        span.circle {
          background-color: #8ad4d4;
          &::before {
            background-image: url('../images/Icon_ionic-ios-happy.svg');
            display: inline-block; } } }

      &:nth-child(6) {
        span.circle {
          background-color: #ffb56b;
          &::before {
            background-image: url('../images/Icon_awesome-hand-holding-heart.svg');
            display: inline-block; } } }

      &:nth-child(7) {
        span.circle {
          background-color: #c9a7ef;
          &::before {
            background-image: url('../images/Icon_awesome-apple-alt.svg');
            display: inline-block; } } } } } }
