.color-blue {
  color: $blue !important; }

.color-white {
  color: $white !important; }

.color-black {
  color: #000; }

.color-gray {
  color: $gray; }

.color-blueLight {
  color: $blueLight; }

section.section-pd-medium {
  padding: 115px 0;

  @include below(desktop) {
    padding: 75px 0; } }

section.section-pd-top {
  padding: 20px 0 100px 0;

  @include below(desktop) {
    padding: 20px 0 75px 0 !important; } }


.container-large {
  padding: 0 12%;
  overflow-x: clip;

  @media(max-width: 1440px) {
    padding: 0 80px; }

  @include below(desktop) {
    padding: 0; }

  .heading-news {
    @include below(desktop) {
      padding: 0 20px; } } }

.heading-1 {
  h2 {
    color: #002a54;
    font-size: 40px;
    font-weight: 400;
    text-align: center;
    line-height: 52px;

    @include below(phablet) {
      font-size: 35px; } }

  strong {
    font-weight: 600; } }

.heading-2 {
  h2 {
    color: #424446;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    line-height: 52px;

    @include below(desktop) {
      font-size: 16px;
      line-height: 40px; } }

  strong {
    font-weight: 600;
    font-size: 40px;
    color: $blue; }

  .preset-text {
    p {
      font-size: 20px;
      line-height: 40px;
      color: #424446; } } }

.heading-3 {
  h2 {
    color: #134374;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    line-height: 35px; }

  strong {
    font-weight: 600;
    font-size: 40px;
    color: $blue; } }


.effect-arrow-link {
  display: inline-block;
  transition: all 0.3s ease;

  &:hover {
    img {
      transform: translateX(+7px); } }

  img {
    margin-left: 10px;
    margin-bottom: 3px;
    transition: all 0.3s ease; } }

.link-single--page {
  margin: 0 auto;
  display: table;
  a {
    text-align: center;
    color: #002a54;
    font-size: 20px;
    font-weight: 500;
    margin-top: 120px;

    @include below(desktop) {
      margin-top: 80px; } } }

.link-single--pageNews {
  margin: 0 auto;
  display: table;
  text-align: center;
  color: #002a54;
  font-size: 20px;
  font-weight: 500;
  margin-top: 120px;
  cursor: pointer;

  @include below(desktop) {
    margin-top: 80px; } }

.text-primary {
  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 48px; } }

.text-secondary {
  color: #424446;
  font-size: 16px;
  font-weight: 400;
  line-height: 34px;
  margin-top: 10px; }

.text-third {}


.title-first {
  color: #002a54;
  font-size: 40px;
  font-weight: 600; }


.text-left {
  text-align: left !important; }

.text-center {
  text-align: center !important; }

.text-right {
  text-align: right !important; }


.button-primary {
  display: flex;

  a {
    margin-top: 40px;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    padding: 0 35px;
    height: 70px;
    box-shadow: 0 3px 8px rgba(4, 127, 255, 0.4);
    border-radius: 15px;
    background-color: #1185ff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    text-align: center;
    &:hover {
      box-shadow: 0 7px 13px rgba(4, 127, 255, 0.4); } } }


.button-primary-auto--width {
  display: inline-block;
  a {
    margin-top: 40px;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    padding: 0 85px;
    height: 70px;
    box-shadow: 0 3px 8px rgba(4, 127, 255, 0.4);
    border-radius: 15px;
    background-color: #1185ff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    text-align: center;

    @include below(desktop) {
      padding: 0 45px; }

    &:hover {
      box-shadow: 0 7px 13px rgba(4, 127, 255, 0.4); } } }


.hearth-icon-left-1 {
  position: relative;

  &:before {
    content: '';
    background: url('../images/hearth-image-chart.svg');
    position: absolute;
    top: 50px;
    left: -50px;
    width: 106px;
    height: 89px;
    display: inline-block;
    background-size: contain;
    z-index: 99; } }

.hearth-icon-left-2 {
  position: relative;

  &:before {
    content: '';
    background: url('../images/hearth-image-chart.svg');
    position: absolute;
    top: 160px;
    left: -50px;
    width: 106px;
    height: 89px;
    display: inline-block;
    background-size: contain;
    z-index: 99; } }

.hearth-icon-right-1 {
  position: relative;

  &:before {
    content: '';
    background: url('../images/hearth-image-chart.svg');
    position: absolute;
    right: -52px;
    top: 50px;
    width: 106px;
    height: 89px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 99;

    @include below(desktop) {
      width: 73px;
      height: 63px;
      right: -38px; } } }

.hearth-icon-right-2 {
  position: relative;

  &:before {
    content: '';
    background: url('../images/hearth-image-chart.svg');
    position: absolute;
    right: -52px;
    top: 160px;
    width: 106px;
    height: 89px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 99;

    @include below(desktop) {
      width: 73px;
      height: 63px;
      right: -38px; } } }

.hearth-icon-right-3 {
  position: relative;

  &:before {
    content: '';
    background: url('../images/hearth-image-chart.svg');
    position: absolute;
    right: -52px;
    bottom: 80px;
    width: 106px;
    height: 89px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 99;

    @include below(desktop) {
      width: 73px;
      height: 63px;
      right: -30px; } } }

.background-gradient-top {
  background: rgb(253,254,255);
  background: linear-gradient(360deg, rgba(253,254,255,1) 0%, rgba(250,253,255,1) 50%, rgba(231,239,255,1) 100%); }


.background-gradient-bottom {
  background: rgb(253,254,255);
  background: linear-gradient(180deg, rgba(253,254,255,1) 0%, rgba(250,253,255,1) 50%, rgba(231,239,255,1) 100%); }


.background-gradient-top--large {
  background: rgb(253,254,255);
  background: linear-gradient(180deg, #fdfeff 0%, #fafdff 0%, #e7efff 35%); }


.filter {


  @include below(desktop) {
    margin-top: 40px; }
  .widget-area {
    display: flex;
    align-items: center;

    .widget_categories,
    .widget_search {
      width: 187px;
      display: inline-block;

      @include below(desktop) {
        width: 49%; } }

    .widget_categories {
      margin-right: 15px; }

    .widget_categories select,
    .widget_search input[type=search] {
      width: 100%;

      border: 1px solid #bdd0e3;
      background-color: #ffffff; }

    .widget_categories select {
      border-radius: 10px;
      color: #7b8d9f;
      font-size: 14px;
      font-weight: 400;
      font-style: normal;
      padding-left: 15px;
      height: 37px; }

    .widget_search {
      position: relative;

      button[type=submit] {
        position: absolute;
        right: 10px;
        background: none;
        text-indent: -99999px;
        background-image: url(../images/search-solid.svg);
        background-repeat: no-repeat;
        width: 18px;
        height: 12px;
        border: none;
        -webkit-background-size: contain;
        -moz-background-size: contain;
        background-size: contain;
        top: 14px;
        filter: invert(79%) sepia(11%) saturate(246%) hue-rotate(167deg) brightness(91%) contrast(96%); } }


    .widget_search input[type=search] {
      border-radius: 20px;
      height: 35px;
      padding: 0 20px 0 15px;
      padding-top: 2px;


      &::placeholder {
        color: #7b8d9f;
        font-size: 14px;
        font-weight: 400; } } } }



.filter-custom {

  form {
    ul {
      display: flex;
      align-items: center;

      li {
        width: 187px;
        display: inline-block;

        @include below(desktop) {
          width: 49%; } }

      .sf-field-search {
        margin-right: 15px; }

      select,
      input[type=text] {
        width: 100%;
        border: 1px solid #bdd0e3;
        background-color: #ffffff; }

      li {
        select {
          border-radius: 10px;
          color: #7b8d9f;
          font-size: 14px;
          font-weight: 400;
          font-style: normal;
          padding-left: 15px;
          height: 37px; } }

      .sf-field-search {
        position: relative;

        label {
          width: 100%; }

        input[type=text] {
          border-radius: 20px;
          height: 35px;
          padding: 0 20px 0 15px;
          padding-top: 2px;

          &::placeholder {
            color: #7b8d9f;
            font-size: 14px;
            font-weight: 400; } } } } } }


.icon-arrow-down {
  color: #b9c6de;
  margin-left: 10px;
  font-size: 20px; }


.font-size-16 {
  font-size: 16px !important; }

.font-size-18 {
  font-size: 18px !important; }

.font-size-20 {
  font-size: 20px !important; }

.font-size-24 {
  font-size: 24px !important; }

.font-size-30 {
  font-size: 30px !important; }

.font-size-36 {
  font-size: 36px !important;
  line-height: 46px !important; }

.font-weight--normal {
  font-weight: 400 !important; }

.font-weight--500 {
  font-weight: 500 !important; }

.font-weight--600 {
  font-weight: 600 !important; }

.font-weight--normal {
  h2 {
    font-weight: 400 !important; } }

.font-weight--500 {
  h2 {
    font-weight: 500 !important; } }

.font-weight--600 {
  h2 {
    font-weight: 600 !important; } }

$height: 120px;

._margin-top-large {
  margin-top: $height / 2;

  @include below(desktop) {
    margin-top: $height / 3; } }

._margin-top-medium {
  margin-top: $height / 3;

  @include below(desktop) {
    margin-top: $height / 4; } }

._margin-top-small {
  margin-top: $height / 4;

  @include below(desktop) {
    margin-top: $height / 6; } }

.link-site-blue {
  color: #1185ff;
  font-weight: 700; }

.hover-blueDark {
  transition: all 0.3s ease;
  &:hover {
    background: $darkBlue; } }

.hoverColorLightBlue {
  transition: all 0.3s ease;
  &:hover {
    color: $blueLight; } }

._margin-auto {
  margin: 0 auto;
  display: table; }


.content-mid-4 {
  height: 100%;

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center; }


  .box-image {
    padding-bottom: 52.25%;
    height: 100%;

    @include below(desktop) {
      height: 200px; }

    > img {
      height: 100%;
      object-fit: cover;
      object-position: center center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 9; }

    .icon {
      z-index: 999; } } }


.height--medium {
  height: 460px !important;

  @include below(desktop) {
    height: 200px !important; } }
