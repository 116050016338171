.nav-mobile {
  position: fixed;
  top: 101px;
  width: 100%;
  background: #fff;
  z-index: 9999;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  padding-bottom: 50px;
  &.is-open {
    opacity: 1;
    visibility: visible;
    overflow: scroll; }

  >ul {
    padding: 70px 0;


    >li {
      text-align: center;
      margin-bottom: 25px;

      &.menu-item-has-children {
        > a {
          display: inline-block;
          &:after {
            content: '';
            background-image: url('../images/chevron-menu.svg');
            width: 13px;
            height: 9px;
            display: inline-block;
            background-repeat: no-repeat;
            margin-left: 8px; } } }

      > a {
        color: $blue;
        font-size: 22px;
        letter-spacing: 0.85px;
        line-height: 26px; } } } }




.nav-mobile {
  .sub-menu__wrapper {
    display: none; }

  .sub-menu {
    margin-top: 20px;

    li {
      white-space: nowrap;
      transition: all 0.3s ease;
      &:hover {
        transform: translateX(+5px); }

      + li {
        margin-top: 10px;
        display: block; }
      a {
        font-size: 14px;
        color: $blue; } } } }
