.ready-footer {
  background: #e6efff;
  padding: 90px 0;

  @include below(desktop) {
    padding: 75px 0; }

  .ready-inner {
    @include above(tablet) {
      margin: 0 auto;
      display: table; }
    h4 {
      color: #134374;
      font-size: 4.6rem;
      font-weight: 600;
      line-height: 64px;
      text-align: center;

      @include below(desktop) {
        font-size: 40px;
        line-height: 57px; } }

    p {
      color: #424446;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 37px;
      margin-top: 30px;
      text-align: center;

      a {
        color: #1185ff;
        font-weight: 600; } }
    .link {
      margin: 0 auto;
      display: table;
      a {
        margin-top: 50px;
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
        padding: 0 30px;
        height: 70px;
        box-shadow: 0 3px 8px rgba(4, 127, 255, 0.4);
        border-radius: 15px;
        background-color: #1185ff;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease;

        &:hover {
          background: $darkBlue; } } } } }

footer {
  background: #e6efff;
  border-top: 1px solid #9fbbf3;

  .social {
    margin-top: 40px;
    float: right;
    @media(max-width: 1280px) {
      float: left; }
    ul {
      display: flex;
      align-items: center;
      li {
        margin-left: 20px;
        a {
          color: #1185ff;
          font-size: 22px; } } } } }


.container-footer {
  padding: 75px 15px;
  display: table;
  margin: 0 auto;

  @include below(desktop) {
    padding: 75px 15px 35px 15px; }

  @media(min-width: 1024px) {
    width: 992px; }

  @media(min-width: 1440px) {
    width: 1320px; } }


.bottom-footer {
  // display: grid
  // grid-template-columns: 20% 20% 27% 26%
  // justify-content: space-between
  @media(min-width: 1024px) {
    display: flex;
    justify-content: space-between; }

  @media(max-width: 1280px) {
    flex-wrap: wrap; }

  .columns {
    padding: 10px 0;

    @media(max-width: 1280px) {
      width: 50%;
      margin-bottom: 45px; }


    @include below(desktop) {
      width: 100%;
      display: table; }

    // > div
    //   +below(desktop)
    //     margin: 0 auto
 }    //     display: table

  .column-2,
  .column-3,
  .column-4 {
    padding-left: 4%;
    padding-right: 2%;

    @include below(desktop) {
      padding: 0; } }

  .column-3,
  .column-4 {
    border-left: 2px solid #a9c5e6;

    @media(max-width: 1280px) {
      border: none; } }

  .links-footer {
    a {
      color: #134374;
      font-size: 18px;
      font-weight: 500;
      display: block;

      + a {
        margin-top: 20px; } } }

  .contact-footer {
    font-size: 18px;
    font-weight: 500;

    > div {
      display: flex;

      + div {
        margin-top: 20px; }

      p {
        color: #86aed8;
        min-width: 80px; }

      a {
        color: #134374;
        word-break: break-all; } } }

  .news-footer {
    min-width: 375px;
    width: 375px;

    @include below(tablet) {
      width: 100%;
      min-width: 100%; }
    .text-1 {
      color: #134374;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 5px; }


    .text-2 {
      color: #134374;
      font-size: 16px;
      font-weight: 400; } } }


.form-news {
  fieldset {
    padding: 0 !important; }

  .frm_style_formidable-style.with_frm_style .form-field {
    margin-bottom: 0; }
  .frm_form_fields {
    position: relative;
    margin-top: 15px;
    input[type=email] {

      width: 97%;
      height: 48px;
      border-radius: 10px;
      border: 1px solid #86aed8;
      background: none;
      padding: 0 55px 0 25px;

      &::placeholder {
        color: #134374; } }

    .frm_button_submit {
      position: absolute;
      right: 30px;
      top: 4px;
      background: none !important;
      padding: 0 !important;
      border-color: none !important;
      border-width: 0 !important;

      svg {
        font-size: 24px;
        color: $blueLight; } } } }
