.why-invest {
  .container {
    @include below(desktop) {
      padding: 0; } }
  .list-why {


    @include above(tablet) {
      display: grid;
      grid-template-columns: repeat(2, 2fr);
      grid-gap: 0 13.0rem; }

    .item {
      margin-top: 150px;

      @include below(desktop) {
        margin-top: 80px; }

      .image {
        width: 90%;
        height: 199px;
        background-size: cover;
        background-position: center center;
        margin-bottom: 30px;
        border-radius: 20px 0 20px 0;

        @include below(desktop) {
          width: 100%;
          border-radius: 0; } }

      .title {
        color: $blue;
        font-size: 36px;
        font-weight: 600;

        @include below(desktop) {
          padding-left: 20px; } }

      .text {
        @include below(desktop) {
          padding-left: 20px; } } } } }

.were-prod {
  .box-cards-bottom {
    margin-top: 25px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    grid-gap: 20px;

    @include above(tablet) {
      display: flex;
      margin-top: 90px; }

    .box {

      @include above(tablet) {
        width: 32%;
        margin-top: 0px; } } } }
