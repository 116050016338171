.page-contact {
  position: relative;

  .hero-pages-inner {
    .image-hero {
      @include above(tablet) {
        height: 828px; } } } }

.content-mid-contact {
  .inner-content {


    @include above(tablet) {
      display: grid;
      grid-template-columns: repeat(2, 2fr);
      grid-gap: 150px; }

    .column-left {
      .preset-text-1 {
        color: #134374;
        font-size: 28px;
        font-weight: 600; } }


    .column-right {
      @include below(desktop) {
        margin-top: 80px; }
      .preset-text-1 {
        color: #1185ff;
        font-size: 26px;
        font-weight: 600;
        padding-left: 10px; } } } }

.infos-footer {
  .box-infos {
    background: #e6efff;
    padding: 10px 40px 40px 40px;
    color: #134374;
    border-radius: 15px;
    display: block;
    margin-top: 20px;
    .row {
      >div {
        margin-top: 30px; } }

    span {
      font-size: 24px;
      font-weight: 600; }

    p {
      font-size: 18px;
      font-weight: 400; }

    a {
      color: $blueLight;
      font-weight: 500; } } }

.info-location {
  margin-top: 40px;
  .box-infos {
    @include above(tablet) {
      width: 70%; } } }

.form-contact {
  margin-top: 30px;

  fieldset {
    padding: 0;
    margin: 0;
    border: none; }

  .frm_form_field {
    margin-top: 15px;
    input[type=text],
    input[type=email],
    input[type=phone] {
      width: 100%;
      height: 74px;
      border-radius: 15px;
      border: 1px solid #86aed8;
      padding: 0 30px;
      font-size: 20px;
      font-weight: 400;
      color: #134374;

      @include above(tablet) {
        width: 538px; }

      &::placeholder {
        color: #134374;
        font-size: 20px;
        font-weight: 400; } }

    textarea {
      width: 100%;
      height: 212px;
      border-radius: 15px;
      border: 1px solid #86aed8;
      padding: 30px;
      font-size: 20px;
      font-weight: 40;
      color: #134374;

      @include above(tablet) {
        width: 538px; }

      &::placeholder {
        color: #134374;
        font-size: 20px;
        font-weight: 400; } } }

  .frm_submit {
    margin-top: 30px;

    button[type=submit] {
      width: 260px;
      height: 70px;
      box-shadow: 0 3px 8px rgba(4, 127, 255, 0.4);
      border-radius: 15px;
      background-color: #1185ff;
      border: none;
      color: #ffffff;
      font-size: 18px;
      font-weight: 600; } } }
