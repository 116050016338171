.button-nav {
	display: none;
	z-index: 9999999;

	@include below(desktop) {
		display: block; }

	.btn-burguer .icon-bar:nth-of-type(2) {
		top: 1px; }

	.btn-burguer .icon-bar:nth-of-type(3) {
		top: 2px; }

	.btn-burguer .icon-bar:nth-of-type(4) {
		top: 3px; }

	.btn-burguer .icon-bar {
		position: relative;
		transition: all 0.3s; }

	.btn-burguer.active .icon-bar:nth-of-type(1) {
		top: 17px;
		transform: rotate(45deg);
		background: #002a54; }

	.btn-burguer.active .icon-bar:nth-of-type(2) {
		background: transparent; }

	.btn-burguer.active .icon-bar:nth-of-type(3) {
		top: -4px;
		transform: rotate(-45deg);
		background: #002a54; }


	.btn-burguer {
    z-index: 9999;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    float: left;
    z-index: 999999;
    position: relative;
    width: 37px; }

	.btn-burguer .icon-bar {
		background: #002a54;
		display: table;
		margin: 0 auto;
		margin-bottom: 8px;
		width: 37px;
		height: 3px;
		border-radius: 7px; } }
