.hero-home {
  background-image: url('../images/background-hero--home.png');
  padding: 90px 240px 90px 150px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center center;
  overflow-x: hidden;

  @media(max-width: 1440px) {
    padding: 90px 50px 90px 50px; }

  @include below(desktop) {
    flex-direction: column;
    padding: 15px;
    background-image: url('../images/background-mobile.png');
    background-position: top right; }

  .title-page {
    position: absolute;
    top: -95px;
    left: 0;

    @include below(desktop) {
      display: none; }

    h1 {
      font-size: 36px;
      color: #002a54;
      font-weight: 600; } }


  .content-hero {
    width: 879px;
    padding: 65px 100px;
    background: $white;
    border-radius: 25px;
    position: relative;
    z-index: 9;

    @media(max-width: 1440px) {
      padding: 30px 40px; }

    @include below(desktop) {
      width: 100%; }



    .title-hero {

      h2 {
        font-size: 50px;
        color: $blue;
        font-weight: 400;
        line-height: 64px;
        margin: 0;

        @media(max-width: 1440px) {
          font-size: 40px;
          line-height: 47px; }

        @include below(phablet) {
          font-size: 34px;
          line-height: 42px; } }

      strong {
        font-weight: 600; } }

    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 37px;
      margin-top: 25px;
      color: $gray; }

    a {
      color: #1185ff;
      font-weight: 700; }

    .link-button {
      a {
        width: 260px;
        height: 70px;
        box-shadow: 0 3px 8px rgba(4, 127, 255, 0.4);
        border-radius: 15px;
        background-color: #1185ff;
        font-size: 18px;
        font-weight: 600;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 38px;

        &:hover {
          background: $darkBlue; }


        @include below(desktop) {
          width: 100%; } } } }

  .image-hero {
    width: 770px;
    height: 828px;
    position: relative;
    overflow: hidden;
    margin-left: -100px;

    @media(max-width: 1440px) {
      height: 570px; }

    @include below(desktop) {
      width: 100%;
      margin-top: -70px;
      margin-right: -30px;
      height: 357px;
      margin-left: 0;
      padding-left: 20px; }

    .inner-image {
      position: absolute;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 25px; } } }

.hero-pages-inner {
  .content-hero {
    width: 980px;

    @media(max-width: 1440px) {
      padding: 30px 35px; }

    @include below(desktop) {
      width: 100%; } }

  .image-hero {
    height: 640px;

    @media(max-width: 1440px) {
      height: 570px; }

    @include below(desktop) {
      width: 100%;
      margin-top: -70px;
      margin-right: -30px;
      height: 357px;
      margin-left: 0;
      padding-left: 20px; } } }

.content-mid-1 {
  padding-bottom: 140px;
  padding-top: 20px;

  @include below(desktop) {
    padding-top: 110px;
    padding-bottom: 50px; }

  .container {
    position: relative;
    &:before {
      content: '';
      background: url(../images/line-mid-1.svg);
      position: absolute;
      left: 210px;
      top: 90px;
      height: 315px;
      width: 20px;
      height: 180px;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 99;

      @include below(desktop) {
        display: none; } } }

  .content-inner {
    + .content-inner {

      margin-top: 115px; }

    .left-content {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: -145px;
        width: 352px;
        height: 344px;
        z-index: 1;
        background-repeat: no-repeat;

        @include below(desktop) {
          left: -50px; } }

      &.-blue {
        &:before {
          background-image: url(../images/icon-light--blue.svg); } }

      &.-yellow {
        &:before {
          background-image: url(../images/icon-light-yellow.svg);
          left: -30px;

          @include below(desktop) {
            left: -70px; } } }

      .text-featured p {
        font-size: 20px;
        color: #1185ff;
        letter-spacing: 4.88px;
        font-weight: 700;
        box-shadow: 0 7px 9px rgba(134, 174, 216, 0.29);
        width: 170px;
        height: 57px;
        box-shadow: 0 7px 9px rgba(134, 174, 216, 0.29);
        border-radius: 5px;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 126px;
        position: relative;
        z-index: 9;

        @include below(desktop) {
          margin-left: 80px; } } }



    h2 {
      color: #002a54;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 40px;

      @include below(desktop) {
        margin-top: 40px; } }


    p {
      color: $gray;
      font-size: 16px;
      line-height: 35px; } } }


.content-mid-2 {
  padding: 90px 0;
  background-image: url('../images/background-mid-2.png');
  background-size: cover;

  @include below(desktop) {
    padding: 50px 0; }

  .content-inner {
    margin-top: 100px;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 100px;
    align-items: center;
    position: relative;

    @include below(desktop) {
      grid-template-columns: repeat(1, 1fr);
      margin-top: 30px;
      grid-gap: 40px; }


    &:before {
      content: '';
      background-image: url('../images/hearth-image-chart.svg');
      width: 147px;
      height: 125px;
      position: absolute;
      left: -70px;
      top: 55px;
      z-index: 999;
      background-size: contain;
      background-repeat: no-repeat;

      @include below(desktop) {
        width: 96px;
        left: 0;
        top: 80px; } }

    img {
      border-radius: 20px; }

    .column-left {
      @include below(desktop) {
        margin: 0 auto;
        display: table;
        padding-left: 30px; } }


    .link {

      margin-top: 40px;

      @include below(desktop) {
        text-align: center; }
      a {
        font-size: 18px;
        color: #002a54;
        font-weight: 500;

        &:hover {
          color: $blueLight; }

        img {
          margin-left: 15px; } } }


    .list-leading {
      ul {
        li {
          display: inline-block;

          @include below(desktop) {
            margin: 0 auto;
            display: table; }

          &:hover {
            .subitem {
              height: auto;
              visibility: visible; } }

          + li {
            margin-top: 45px;

            @include below(desktop) {
              margin-top: 35px; } }


          .item {
            padding: 25px 35px;
            box-shadow: 0 3px 15px rgba(90, 81, 212, 0.15);
            border-radius: 15px;
            background-color: #ffffff;
            color: #002a54;
            font-size: 24px;
            font-weight: 500;
            display: inline-block;
            align-items: center;
            cursor: pointer;


            @include below(desktop) {
              font-size: 18px; }


            img {
              margin-left: 30px;

              @include below(desktop) {
                margin-left: 15px; } } } } }

      .subitem {
        display: none;
        margin-top: 30px;

        @include above(tablet) {
          width: 80%; }
        p {
          font-size: 12px;
          line-height: 24px; }
        a {
          color: #002a54;
          margin-left: 10px;
          font-weight: 500;

          img {
            width: 12px;
            vertical-align: middle;
            margin-left: 5px;
            margin-top: 2px; } } } } } }

.content-mid-3 {
  margin-bottom: 230px;

  @include below(desktop) {
    margin-bottom: 100px; }

  .box-cards-top {

    @include above(phablet) {
      display: grid;
      grid-template-columns: repeat(3, 3fr);
      grid-gap: 25px; }

    @include below(tablet) {

      grid-gap: 15px; }

    @include below(phablet) {
      padding: 0 25px; }

    .box-text {
      position: relative;
      z-index: 9;
      width: 100%;
      span {
        font-size: 80px;
        color: #002a54;
        font-weight: 700;
        line-height: 90px;

        @include below(desktop) {
          font-size: 40px;
          line-height: 50px; } }
      p {
        color: #002a54;
        font-size: 36px;
        font-weight: 500;
        line-height: 45px;

        @include below(desktop) {
          font-size: 24px;
          line-height: 35px; } } }

    .boxes {
      @include below(desktop) {
        margin-top: 30px;
        display: inline-block; } }

    .box-1 {
      .box-text {
        background-color: rgba(150, 180, 241, 0.35);
        padding: 50px 50px 90px 50px;
        border-radius: 0 20px 0 20px;

        @include below(desktop) {
          padding: 20px 30px 80px 30px; } }

      .box-image {
        margin-top: -65px;
        float: right;

        @include below(tablet) {
          padding-left: 30px; } } }

    .box-2 {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column-reverse;
      clear: both;

      .box-text {
        background-color: rgba(138, 212, 212, 0.35);
        padding: 65px 50px 35px 50px;
        border-radius: 20px 0 20px 0;
        margin-top: -75px;

        @include below(desktop) {
          padding: 50px 30px 50px 30px; }

        @include below(tablet) {
          padding: 20px 30px 20px 30px; }

        span {
          font-size: 60px;
          line-height: 70px;

          @include below(desktop) {
            font-size: 40px;
            line-height: 50px; } } }

      .box-image {
        margin-top: 0;

        @include below(tablet) {
          padding: 0 15px; } } }


    .box-3 {
      .box-text {
        background-color: rgba(255, 181, 107, 0.35);
        padding: 50px 50px 90px 50px;
        border-radius: 20px 0 20px 0;

        @include below(desktop) {
          padding: 20px 30px 120px 30px; }

        @include below(tablet) {
          padding: 20px 30px 90px 30px; } }

      .box-image {
        float: left;
        margin-top: -65px;

        @include below(tablet) {
          padding-right: 30px; } } } }



  .box-cards-bottom {
    @include above(phablet) {
      display: grid;
      grid-template-columns: repeat(3, 3fr);
      grid-gap: 25px; }

    @include below(desktop) {
      margin-top: 35px; }

    .box {
      color: #002a54;
      font-family: Poppins;
      padding: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 40px;
      border-radius: 20px 0 20px 0;

      @include below(desktop) {
        padding: 25px; }

      @include below(desktop) {
        margin-top: 20px; }
      span {
        font-size: 35px;
        font-weight: 700;

        @include below(desktop) {
          font-size: 29px; } }

      p {
        font-size: 22px;
        font-weight: 500;

        @include below(desktop) {
          font-size: 20px; } }

      &:nth-child(1) {
        background-color: rgba(138, 212, 212, 0.35); }


      &:nth-child(2) {
        background-color: rgba(255, 181, 107, 0.35); }

      &:nth-child(3) {
        background-color: rgba(150, 180, 241, 0.35); }

      &:nth-child(4) {
        background-color: rgba(201, 167, 239, 0.35); }

      &:nth-child(5) {
        background-color: rgba(138, 177, 212, 0.35); } } } }


.content-mid-4 {

  padding-bottom: 135px;

  @include below(desktop) {
    padding-bottom: 75px; }

  .content-inner {
    display: flex;
    justify-content: space-between;
    margin-top: 90px;

    @include below(desktop) {
      flex-direction: column;
      margin-top: 0; }

    .column {
      width: 100%;
      @include above(tablet) {
        width: 45%; } }


    .list-topics {
      margin-top: 30px;
      ul {

        @include above(tablet) {
          column-gap: 40px;
          column-count: 2; }
        li {} } }

    .column-left {
      @include below(desktop) {
        margin-top: 50px; } }

    .content-text {
      + .content-text {
        @include above(tablet) {
          margin-top: 50px; } }



      ul {
        margin-top: 10px;
        color: #424446;
        font-size: 16px;
        font-weight: 400;
        margin-top: 35px;

        li + li {
          margin-top: 15px; } }


      h2 {
        color: $blue;
        font-size: 40px;
        font-weight: 600;
        line-height: 52px; }

      h3 {
        color: $blueLight;
        font-size: 24px;
        font-weight: 600;
        margin-top: 15px; }

      p {
        color: #424446;
        font-size: 16px;
        font-weight: 400;
        line-height: 34px;
        margin-top: 15px; } }


    .box-image {
      img {
        width: 100%;
        border-radius: 25px; } } } }


.content-mid-5 {
  padding: 125px 0;

  @include below(desktop) {
    padding: 90px 0; }

  .container {
    position: relative;
    &::before {
      content: '';
      background: url('../images/blur-yellow-content-mid-5.svg');
      position: absolute;
      left: -200px;
      top: 280px;
      width: 684px;
      height: 647px;
      z-index: -1; } }

  .content-card--studies {
    position: relative;
    margin-top: 60px;

    @include above(tablet) {
      padding: 0 60px;
      margin-top: 80px; }




    .card-inner--studies {

      box-shadow: 0 5px 25px rgba(179, 185, 207, 0.3);
      border-radius: 25px;
      background-color: #ffffff;
      padding: 20px 20px 50px 20px;
      display: inline-block;
      align-items: center;

      @include above(tablet) {
        grid-template-columns: 38% 53%;
        grid-gap: 9%;
        display: grid;
        padding: 30px 70px 30px 30px; }

      + .card-inner--studies {
        margin-top: 35px; }

      &:hover {
        background: $blue;
        .card-title,
        .card-subtitle,
        .card-text,
        .card-link {
          color: #fff; } }

      .card-text--studies {
        color: $blue;
        @include below(desktop) {
          margin-top: 55px; }

        .card-title {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 10px;

          @include below(desktop) {
            text-align: center; } }

        .card-subtitle {
          font-size: 18px;
          font-weight: 400;


          @include below(desktop) {
            text-align: center; } }

        .card-text {
          margin-top: 45px;
          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 33px; } }

        .card-link {
          font-size: 18px;
          font-weight: 500;
          float: right;
          margin-top: 20px;

          a {
            &:hover {
              color: $blueLight; } }

          @include below(desktop) {
            float: left; }

          img {
            width: 18px; } } }


      .card-image--studies {
        height: 316px;
        border-radius: 20px;
        background-size: cover;
        background-position: center center; } } } }


.post-categories {
  display: flex;
  li {


    &:after {
      content: '|';
      display: inline-block;
      margin: 0 10px; }

    &:last-child {
      &::after {
        display: none; } } } }

.page-case-studies {
  .card-inner--studies {
    transition: all 0.3s ease;
    &:hover {
      background: $blue;

      .card-text--studies {
        color: #fff; } } } }



.content-mid-6 {
  padding: 125px 0;
  background: linear-gradient(360deg, rgba(253,254,255,1) 0%, rgba(250,253,255,1) 50%, rgba(231,239,255,1) 100%);

  @include below(desktop) {
    padding: 75px 0; }

  .card-news--content {
    margin-top: 60px;
    position: relative;
    &::before {
      content: '';
      background: url('../images/blur-yellow-content-mid-5.svg');
      position: absolute;
      right: -200px;
      top: 70px;
      width: 684px;
      height: 647px;
      z-index: 1; }

    @include above(tablet) {
      grid-template-columns: repeat(3, 3fr);
      grid-gap: 60px;
      display: grid;
      margin-top: 100px; }

    .card-news-inner {
      color: $blue;
      position: relative;
      z-index: 9;
      @include below(desktop) {
        + .card-news-inner {
          margin-top: 40px; } }

      .card-text-content {
        box-shadow: 0 5px 25px rgba(179, 185, 207, 0.3);
        border-radius: 25px;
        background-color: #ffffff;
        margin-top: -25px;
        z-index: 0;
        position: relative;
        padding: 70px 35px 60px 35px;
        display: inline-block;
        transition: all 0.3s ease;
        width: 100%;
        &:hover {
          background: $blue;
          color: #fff; } }

      .card-title {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 10px; }

      .card-subtitle {
        font-size: 18px;
        font-weight: 400;
        display: flex;
        flex-wrap: wrap;
        text-align: center;

        @include below(tablet) {
          font-size: 16px; }

        .post-categories {
          margin-right: 5px; } }

      .card-text {
        margin-top: 45px;
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 33px; } }

      .card-link {
        font-size: 18px;
        font-weight: 500;
        float: right;
        margin-top: 30px;

        img {
          width: 18px; } } }

    .card-image {
      width: 90%;
      height: 247px;
      border-radius: 20px 20px 20px 0;
      background-size: cover;
      background-position: center center;
      position: relative;
      z-index: 1; } } }

.section-mid-6 {
  background-size: cover;
  padding: 130px 0;
  background-position: center center;
  @include below(desktop) {
    padding: 100px 0; }

  .content-testimonials {

    border-radius: 25px;
    background-color: #ffffff;
    padding: 55px 30px 140px 30px;

    @include above(tablet) {
      width: 889px;
      display: inline-block;
      padding: 55px 100px; }

    .text-reviews {
      @include above(tablet) {
        display: flex; }
      p {
        color: #002a54;
        font-size: 40px;
        font-weight: 600;
        margin-right: 15px; }

      ul {
        display: flex;
        align-items: center;
        li.hearth-testimonials {
          background: url('../images/hearth-icon-testimonials.svg');
          width: 31px;
          height: 27px;
          margin-left: 10px;

          @include below(desktop) {
            margin-left: 0;
            margin-top: 20px;
            margin-bottom: 10px;
            margin-right: 10px; } } } }

    .text-1 {
      color: #424446;
      font-size: 20px;
      margin-top: 15px; }

    .arrow-testimonials {
      float: right;
      display: flex;
      margin-top: 25px;
      li {
        cursor: pointer;
        width: 35px;
        height: 35px;
        border: 3px solid #1185ff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: #1185ff;
        font-size: 18px;
        letter-spacing: 0;
        margin-left: 30px;
        transition: all 0.3s ease;

        @include below(desktop) {
          margin-top: 35px; }

        &:hover {
          background: #1185ff;
          color: $white; }

        &:nth-child(2) {
          padding-left: 3px; } } } }

  .carousel-testimonials {
    margin-top: 90px;
    @include below(desktop) {
      margin-top: 70px; }
    .inner-carousel {

      @include above(tablet) {
        display: grid;
        grid-template-columns: 10% 85%;
        grid-gap: 5%;
        align-items: center; }

      span.bloquote-icon {
        background: url('../images/bloquote-icon.png');
        width: 50px;
        height: 50px;
        background-size: cover;
        display: inline-block;
        margin-bottom: 10px;
        @include above(tablet) {
          width: 66px;
          height: 68px; } }

      .text-carousel {
        .description {
          color: #707070;
          font-size: 20px;
          font-weight: 500;
          line-height: 39px; }

        .name {
          color: #1185ff;
          font-family: Poppins;
          font-size: 18px;
          margin-top: 10px; } } } } }

.section-whychoose-home {
  .hearth-icon-right-1 {
    &:before {
      bottom: 100px;
      top: auto;
      width: 79px;
      right: -20px; } } }


.content-mid-4 {
  p {
    a {
      color: $blueLight;
      font-weight: 600; } } }

.content-mid-6 {
  .card-videos {
    .card-image {
      width: 100%;
      border-radius: 0;
      height: 270px;

      @include below(desktop) {
        height: 210px; } } } }
