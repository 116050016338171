.page-services {}

.service-content {
  background: linear-gradient(180deg, rgba(253,254,255,1) 0%, rgba(250,253,255,1) 30%, rgba(231,239,255,1) 50%);
  padding-bottom: 130px;

  @include below(desktop) {
    padding-bottom: 45px; }

  .listing-services {
    @include below(desktop) {
      margin-bottom: 30px;
      padding: 0 15px;
      display: inline-block; }
    .image-service {
      position: relative;
      width: 90%;
      padding-bottom: 56.25%;
      height: 247px;
      float: right;
      .image-inner {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: 20px; } }

    .title-service {
      border-radius: 20px 0 20px 0;
      background-color: #ffffff;
      font-size: 27px;
      font-weight: 700;
      color: #002a54;
      margin-top: -65px;
      z-index: 99;
      position: relative;
      padding: 20px 20px;
      display: table;
      float: left;

      &.color-1 {
        background-color: #8ad4d4; }

      &.color-2 {
        background-color: #ffb56b; }

      &.color-3 {
        background-color: #96b4f1; }

      &.color-4 {
        background-color: #c9a7ef; } }

    .list-topics {
      margin-top: 30px;
      color: #134374;
      font-size: 17px;
      font-weight: 500;
      float: left;

      ul {
        li {
          display: block;

          + li {
            margin-top: 20px; } } } } } }


.content-mid-service-3 {
  .list-adm--services {
    margin-top: 110px;
    display: grid;
    justify-content: space-between;

    @include above(tablet) {
      grid-template-columns: repeat(2,2fr);
      grid-gap: 8.0rem 11.5rem; }

    .title {
      color: #1185ff;
      font-size: 24px;
      font-weight: 600; } } }
