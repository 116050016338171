.page-single-post {
  margin-bottom: 115px;

  .container {
    position: relative;

    &::before {
      content: '';
      background: url('../images/blur-yellow-content-mid-5.svg');
      position: absolute;
      right: -200px;
      bottom: 0px;
      width: 684px;
      height: 647px;
      z-index: 1; } }


  .back-news {
    color: #134374;
    font-size: 18px;
    font-weight: 500;
    padding: 45px 0;
    svg {
      margin-right: 20px;
      display: inline-block; } }


  .inner-singlePost {
    position: relative;
    z-index: 9;
    box-shadow: 0 5px 25px rgba(179, 185, 207, 0.3);
    border-radius: 25px;
    background-color: #ffffff;
    padding: 4.0rem 15px;
    @include above(tablet) {
      padding: 8.0rem 14.0rem; }


    .title-post {

      h2 {
        font-weight: 500;
        @include below(desktop) {
          text-align: center !important;
          font-size: 24px;
          font-weight: 600;
          line-height: 42px; } } }

    .content-1 {
      display: flex;
      align-items: center;
      margin-top: 20px;

      @include below(desktop) {
        flex-direction: column;
        align-items: center; }


      .cat-date {
        color: #134374;
        font-size: 18px;
        font-weight: 400;
        display: flex;
        align-items: center;
        text-align: center;

        .post-categories {
          padding-right: 8px; } }

      .social-post {
        display: flex;
        align-items: center;
        margin-left: 50px;
        font-size: 16px;
        color: #86aed8;

        @include below(desktop) {
          margin-left: 0;
          margin-top: 20px; }

        ul {
          li {
            display: inline-block;
            margin-left: 15px; } } } }

    .image-post {
      padding-bottom: 56.25%;
      position: relative;
      height: 100%;
      width: 100%;
      overflow: hidden;
      background-size: cover;
      background-position: center center;
      margin-top: 60px;

      @include below(desktop) {
        margin-top: 30px; }

      .thumb-single {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        border-radius: 25px; } }

    .text-post {
      margin-top: 60px;
      display: block;
      color: #134374;

      @include below(desktop) {
        margin-top: 40px; }

      p {
        + p {
          margin-top: 40px; } }

      ul {
        padding-left: 30px;
        margin: 15px 0;
        li {
          list-style: disc; } }

      a {
        color: $blueLight; } }

    .pagination-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 70px;
      align-items: center;

      @include below(desktop) {
        justify-content: space-between; }


      a {
        color: #134374;
        font-size: 18px;
        font-weight: 500;
        margin-left: 85px;

        @include below(desktop) {
          margin-left: 0; } }

      svg {
        margin-left: 5px;
        margin-right: 5px;
        font-size: 18px; } } } }


.page-single-video {
  padding-top: 115px;
  @include below(desktop) {
    padding-top: 75px; }
  .inner-singlePost {
    width: 100%;
    padding: 0;

    .title-post {}


    .image-post {
      border-radius: 0; }

    .inner-content {
      text-align: center;
      padding: 40px 30px;
      @include above(tablet) {
        width: 900px;
        margin: 0 auto;
        padding: 70px 50px; }

      .content-1 {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        margin-top: 40px; }


      .text-post {
        color: #424446; } } }

  .backlinks-video {
    margin: 0 auto;
    display: table;
    color: #134374;
    font-size: 18px;
    font-weight: 500;
    margin-top: 50px;

    svg {
      margin-right: 10px; } } }
