.section-mid-it-works-1 {
  padding: 20px 0 100px 0; }


.section-mid-it-works-2 {
  background: rgb(230,239,255);
  background: linear-gradient(180deg, rgba(230,239,255,1) 0%, rgba(238,245,255,1) 50%, rgba(253,254,255,1) 100%); }

.listing-process {
  position: relative;
  @include below(desktop) {
    text-align: center; }

  &::before {
    content: '';
    position: absolute;
    background-image: url('../images/line-mobile-how.png');
    height: 2px;
    width: 82%;
    top: 40px;
    display: table;
    margin: 0 auto;

    @include below(desktop) {
      display: none; } }

  .item {
    @include below(desktop) {
      margin-top: 80px; } }

  @include above(tablet) {
    display: grid;
    grid-template-columns: repeat(5, 5fr);
    grid-gap: 40px;
    margin-top: 130px; }

  .number {
    position: relative;
    margin-bottom: 30px;

    @include below(desktop) {
      margin-bottom: 25px; }


    &::before {
      content: '';
      background-image: url('../images/line-mobile-how.png');
      position: absolute;
      width: 110%;
      height: 2px;
      left: -20px;
      top: 38px;
      z-index: 1;

      @include above(tablet) {
        display: none; } }

    span {
      width: 80px;
      height: 80px;
      box-shadow: 0 3px 43px rgba(0, 81, 203, 0.11);
      background-color: #ffffff;
      color: #002a54;
      font-size: 48px;
      font-weight: 600;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin:  0 auto;

      @include below(desktop) {
        width: 80px;
        height: 80px;
        font-size: 48px;
        margin: 0 auto;
        display: flex;
        position: relative;
        z-index: 9; } } }

  .title {
    color: #1185ff;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;

    @include below(desktop) {
      margin-top: 20px; } }

  .text {
    color: #134374;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    text-align: center; }

  .image {
    position: relative;

    &:before {
      content: '';
      background: url('../images/hearth-image-chart.svg');
      position: absolute;
      right: -15px;
      top: 40px;
      width: 106px;
      height: 89px;
      display: inline-block;
      background-size: contain; } } }


.section-reduce {
  .content-inner {
    .column {
      padding: 0 15px; }


    &:nth-child(2n+1) {
      .box-image {
        &:before {
          right: -73px;

          @include below(desktop) {
            right: -20px; } } } }

    &:nth-child(2n) {

      .box-image {
        &:before {
          left: -73px;

          @include below(desktop) {
            left: -20px; } } } } }



  .box-image {
    position: relative;

    &:before {
      content: '';
      background: url('../images/hearth-image-chart.svg');
      position: absolute;
      top: 40px;
      width: 146px;
      height: 124px;
      display: inline-block;
      background-size: contain;
      z-index: 99;

      @include below(desktop) {
        width: 83px;
        height: 71px; } } } }


.content-mid-4 {
  .extraText bdi {
    transition: all 0.3s;
    display: none;

    &.active {
      display: block !important; } }

  .showText {
    color: #134374;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;

    &.active {
      svg {
        transform: rotate(-180deg); } }

    svg {
      color: #b9c6de;
      margin-left: 5px;
      transition: all 0.3s; } } }
