.container {
  @include below(desktop) {
    overflow-x: clip; } }
.gallery-images {
  position: relative;

  .item {
    padding-bottom: 29.25%;
    position: relative;
    overflow: hidden;
    // width: 50%
    // height: 100%

    @include below(desktop) {
      padding-bottom: 14.20%; }

    .image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center; } }



  .arrow-testimonials {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    width: 100%;
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: transparent;
      font-size: 36px;
      letter-spacing: 0;
      transition: all 0.3s ease;
      color: #0051cb;
      position: absolute;

      &.prev {
        left: 75px;

        @include below(desktop) {
          left: 15px; } }

      &.next {
        right: 75px;

        @include below(desktop) {
          right: 15px; } } } } }

.links-benefit {
  margin-top: 90px; }

.link-brochure {
  margin-top: 30px;
  a {
    color: #002a54;
    font-size: 20px;
    font-weight: 500;

    svg {
      margin-left: 20px;
      color: #b9c6de; } } }


// Page Community

.page-community {
  .text-smallbusiness {
    margin-top: 20px;
    @include above(tablet) {

      width: 900px;
      margin: 0 auto;
      display: table;
      margin-top: 40px; }
    p {
      color: $gray;
      font-size: 16px;
      font-style: normal;
      letter-spacing: normal;
      line-height: 27px; } } }

.faq-questions {
  margin-top: 80px;

  .item {

    @include above(tablet) {
      width: 1060px;
      margin: 0 auto;
      display: table; }

    + .item {
      border-top: 1px solid #b9c6de;
      padding-top: 50px;
      margin-top: 50px; }

    .text-secondary {

      p {
        // overflow: hidden
        // -o-text-overflow: ellipsis
        // text-overflow: ellipsis
        // display: -webkit-box
        // -webkit-line-clamp: 1
 }        // -webkit-box-orient: vertical

      .morecontent {
        span {
          display: none; } }
      .morelink {
        color: $blue; }

      svg {
        color: #b9c6de;
        margin-left: 5px; } }
    a {
      color: $blueLight;
      font-weight: 600; } } }

.morecontent {
  span {
    display: none; } }

.morelink {
  color: $blue !important; }

.more {
  a {
    color: $blueLight;
    font-weight: 600; } }

.title-page--mobile {
  padding: 35px 30px;
  color: #002a54;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;

  @include above(tablet) {
    display: none; } }

figure {
  img {
    height: auto; } }


.filter-locations {
  display: flex;
  justify-content: center;
  a {
    color: #134374;
    font-size: 18px;
    font-weight: 600; }

  select {
    margin-left: 50px;
    color: #134374;
    font-size: 18px;
    font-weight: 600;
    border: none;
    border-bottom: 1px solid #86aed8;
    padding-bottom: 5px;
    padding-right: 50px;
    cursor: inherit;
    appearance: none;
    background-image: url('../images/chevron-menu.svg');
    background-repeat: no-repeat;
    background-position: right 10px; } }

.content-locations {
  display: flex;
  justify-content: center;
  margin-top: 90px;
  flex-direction: column;

  .image-icon {
    font-size: 48px;
    color: $blueLight;
    margin-right: 20px; }

  .item {
    justify-content: center;
    color: #134374;
    display: flex;
    flex-direction: column;
    align-items: center;
    + {
      .item {
        margin-top: 85px;

        @include below(desktop) {
          margin-top: 40px; } } }

    .location-top {
      display: flex; }

    .locationName {
      font-size: 22px;
      font-weight: 600; }
    .locationAddress {
      font-size: 18px; }

    .locationLink {

      @include above(tablet) {
        display: flex; }

      @include below(desktop) {
        display: flex;
        flex-direction: column;
        align-items: center; }

      a {
        height: 58px; }

      a:first-child {
        margin-right: 25px;

        @include below(desktop) {
          margin-right: 0; } }

      .bg-white {
        a {
          color: #1185ff;
          background: #fff;
          border: 1px solid #86aed8;
          box-shadow: none;

          @include below(desktop) {
            margin-top: 20px;
            padding: 0 15px;
            width: 100%; } } } } } }

.iframe-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 100%;
  overflow-x: hidden;
  width: 100%;
  display: block;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; } }

.ready-phil {
  p {
    line-height: 28px !important; } }


.effect-hover-scale {
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.1,1.1); } }

.section-pd-top {
  .preset-text {
    p {
      @include above(tablet) {
        margin: 0 auto;
        width: 1095px; } } } }


.ready-phil {
  @include above(desktop) {
    padding: 0 200px !important; }

  @include below(desktop) {
    .item {
      margin-top: 0 !important; } }

  .our {
    color: #002a54;
    font-size: 24px;
    font-weight: 600;
    margin-top: 65px;

    @include below(desktop) {
      text-align: center;
      margin-top: 25px; } } }

.thumb-video {
  .card-image {
    padding-bottom: 52.25%;
    overflow: hidden;
    position: relative;

    .inner-image {
      position: absolute;
      left: 0;
      width: 100%;
      top: 0;
      height: 100%;
      background-position: center center; } } }


.hero-communitycare-locations {
  .image-hero {
    height: 500px;

    @include below(desktop) {
      height: 207px;
      margin-top: -15px; } } }
