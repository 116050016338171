@keyframes menuFixed {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}



@keyframes scrollEffecit {
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@keyframes circleRotate {
  from {
       transform: rotate(0deg);
   } to {
       transform: rotate(360deg);
   }
}
