.page-case-resources {

  position: relative;

  .card-content-resources {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px;
    margin-top: 40px;
    position: relative;

    @include above(tablet) {
      display: grid;
      grid-template-columns: repeat(3, 3fr);
      grid-gap: 40px;
      margin-top: 90px; }

    &::before {
      content: '';
      background: url('../images/blur-yellow-content-mid-5.svg');
      position: absolute;
      left: -200px;
      top: 70px;
      width: 684px;
      height: 647px;
      z-index: -1; }

    .card-inner {
      box-shadow: 0 5px 25px rgba(179, 185, 207, 0.3);
      border-radius: 25px;
      background-color: #ffffff;
      padding: 30px 50px 50px 50px;
      transition: all 0.3s ease;
      &:hover {
        background: $blue;
        .card-title {
          color: #fff; } } }

    .card-image {
      width: 100%;
      height: 250px;
      border-radius: 25px;
      background-size: cover;
      background-position: center center; }

    .card-title {
      color: #134374;
      font-size: 24px;
      font-weight: 600;
      display: inline-block;
      margin-top: 20px;
      margin-bottom: 20px; }

    .link-download {
      a {
        float: right;
        padding: 10px 25px;
        border-radius: 10px;
        background-color: #1185ff;
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        border: 0;
        margin-top: 20px; } }

    .card-form {
      input[type=email] {
        width: 100%;
        color: #134374;
        font-size: 18px;
        font-weight: 400;
        border-radius: 10px;
        border: 1px solid #b9c6de;
        background-color: #ffffff;
        height: 55px;
        padding: 0 20px;

        &::placeholder {
          color: #134374;
          font-weight: 400; } }

      input[type=submit] {
        float: right;
        padding: 10px 25px;
        border-radius: 10px;
        background-color: #1185ff;
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        border: 0;
        margin-top: 20px; } } } }
