
.page-news {
  .card-news--content {
    @include below(desktop) {
      margin-top: 40px; } } }

.heading-news {
  @include above(tablet) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }

  .heading-2 {
    h2 {
      text-align: left; } } }
