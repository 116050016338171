.carousel-why {
  margin-top: 90px;
  padding: 0 80px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include below(desktop) {
    margin-top: 60px;
    padding: 0 5px 0px 5px; }

  .item {
    width: 21%;
    margin-top: 60px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    @include below(desktop) {
      width: 47%; }
    img {
      margin: 0 auto;
      display: table;

      @include below(desktop) {
        padding: 0 30px; } } } }


.section-mission {
  .box-image {
    height: 264px;
    margin-bottom: 40px;
    position: relative;
    img {
      height: 100%;
      object-fit: cover;
      border-radius: 25px;
      object-position: center center; } }

  .column {
    position: relative;

    @include below(desktop) {
      padding: 0 15px; }

    .icon {
      position: absolute;
      left: -10px;
      bottom: -20px;

      @include below(desktop) {
        width: 70px; } }

    .icon-mission {
        position: absolute;
        right: -20px;
        top: 20px;
        z-index: 99;

        @include below(desktop) {
          width: 70px; } } } }

.column-mission {
  @include below(desktop) {
    width: 100%; } }

.section-core {
  .heading-1 {
    margin-bottom: 90px;
    display: block; } }

.core-content {
  @include above(tablet) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }



  .item {
    display: flex;
    align-items: center;
    margin-top: 25px;

    @include below(desktop) {
      margin-top: 15px; }

    &:nth-child(1) {
      p {
        background-color: rgba(138, 212, 212, 0.35); } }

    &:nth-child(2) {
      p {
        background-color: rgba(255, 181, 107, 0.35); } }

    &:nth-child(3) {
      p {
        background-color: rgba(150, 180, 241, 0.35); } }

    &:nth-child(4) {
      p {
        background-color: rgba(201, 167, 239, 0.35); } }

    &:nth-child(5) {
      p {
        background-color: rgba(138, 177, 212, 0.35); } }

    p {
      font-size: 35px;
      font-weight: 600;
      color: #002a54;
      line-height: 42px;
      margin-left: 35px;
      border-radius: 25px 0 25px 0;
      display: flex;
      padding: 25px 50px;
      justify-content: center;

      @include below(desktop) {
        font-size: 26px;
        line-height: 32px;
        margin-left: 0;
        width: 100%; } } } }


.team-content {

  margin-top: 90px;

  @include above(tablet) {
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    grid-gap: 40px; }

  .item {
    @include below(desktop) {
      + .item {
        margin-top: 35px; } }

    .image-team {
      border-radius: 25px;
      height: 330px;
      background-size: cover;
      background-position: center center; }

    .name {
      margin-top: 35px;
      font-size: 32px;
      font-weight: 600;
      color: #002a54; }

    .title {
      font-size: 24px;
      margin-top: 10px; } } }

.ready-why {
  display: inline-block;
  margin: auto !important;
  justify-content: space-around;

  @include above(tablet) {
    padding: 0 100px; }

  @include below(desktop) {
    padding-bottom: 30px; }

  .item {
    + .item {
      @include below(desktop) {
        margin-top: 50px; } } }

  .link {

    a {
      width: 350px !important;

      @include below(desktop) {
        width: 100% !important;
        margin-top: 30px !important; } } } }

.modal-team {

  padding: 35px 15px !important;
  position: relative;
  box-shadow: 0 0 10px #838383 !important;
  border-radius: 15px !important;

  @media(min-width: 1140px) {
    width: 1100px !important;
    max-width: 1100px !important;
    padding: 40px !important; }

  .close-modal {
    display: none !important; }

  .modalCloase {
    position: absolute;
    right: 12px;
    top: 5px;
    font-size: 20px;
    color: $blue;

    @media(min-width: 1140px) {
      top: 15px;
      right: 20px;
      font-size: 26px; } }

  .inner-content {
    @media(min-width: 1140px) {
      display: grid;
      grid-template-columns: repeat(2, 2fr); } }

  .name {
    font-size: 32px;
    font-weight: 600;
    color: #002a54; }

  .title {
    font-size: 24px;
    color: #002a54;
    font-weight: 500;
    margin-top: 10px; }

  .bio {
    margin-top: 10px;
    p {
      font-size: 16px;
      line-height: 37px;
      color: #134374;

      @media(min-width: 1140px) {
        margin-top: 25px; } } }

  .image-team {
    width: 100%;
    height: 220px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 25px;

    @media(min-width: 1140px) {
      width: 439px;
      height: 442px; } } }

.jquery-modal.blocker {
  z-index: 9999 !important;
  padding: 20px 5px !important; }
