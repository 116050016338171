header {
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 9999;

  .header {
    padding: 30px 5% 30px 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d7e0ef;
    z-index: 999;
    position: relative;

    @media(max-width: 1200px) {
      padding: 30px 15px 30px 15px; } } }
