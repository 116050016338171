.menu-content {
  @include below(desktop) {
    display: none; }
  nav {
    > {}
    >ul {
      >li {
        display: inline-block;
        margin-left: 25px;
        z-index: 999;

        &:hover {
          .sub-menu__wrapper {
            opacity: 1;
            visibility: visible;
            z-index: 9; } }
        &:first-child {
          margin-left: 0; }

        &.menu-item-has-children {
          > a {
            display: inline-block;
            &:after {
              content: '';
              background-image: url('../images/chevron-menu.svg');
              width: 13px;
              height: 9px;
              display: inline-block;
              background-repeat: no-repeat;
              margin-left: 8px; } } }

        @media(max-width: 1600px) {
          margin-left: 20px; }

        @media(max-width: 1440px) {
          margin-left: 10px; }

        > a {
          font-family: $sans-serif;
          font-size: 18px;
          font-weight: 500;
          color: $blue;

          &:hover {
            color: $blueLight; }

          @media(max-width: 1600px) {
            font-size: 14px; } } } } } }


.menu-content {
  .sub-menu__wrapper {
    position: absolute;
    left: 0;
    width: 100%;
    padding-top: 42px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: -1;

    ul {
      width: 100%;
      background: #fff;
      z-index: 999;
      text-align: center;
      display: flex;
      justify-content: center;
      padding: 35px 0;

      li {
        display: inline-block;
        margin-left: 35px;

        &.subName-nav {
          a {
            font-size: 18px;
            font-weight: 700;
            border-right: 1px solid #D7E0EF;
            padding-right: 40px;
            margin-right: 5px; } }

        a {
          font-family: $sans-serif;
          font-size: 16px;
          font-weight: 500;
          color: $blue;
          padding: 20px 0;

          &:hover {
            color: $blueLight; } } } } } }


// #menu-item-147 .sub-menu,
// #menu-item-148 .sub-menu
//   display: none
